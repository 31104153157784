<template>
  <div id="details">
    <div class="header">
      <div class="addBtn" v-if="rentable">
        <b-button @click="rentModal">Dodijeli </b-button>
      </div>
      <div class="unrentBtn" v-if="!rentable">
        <b-button @click="unrent"
          >{{ $t("equipment_document.unrent") }}
        </b-button>
      </div>
    </div>
    <b-spinner small v-if="loading"></b-spinner>

    <div class="content" v-if="!loading">
      <div class="container">
        <div class="row">
          <div class="col-12 mt-2">
            <div class="title">Naziv</div>
            <div class="content">
              <b>{{ equipment.name }}</b>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4 mt-2">
            <div class="title">Serijski broj</div>
            <div class="content">{{ equipment.serial_number }}</div>
          </div>
          <div class="col-12 col-md-4 mt-2">
            <div class="title">Inventarski broj</div>
            <div class="content">{{ equipment.inventory_number }}</div>
          </div>
          <div class="col-12 col-md-4 mt-2">
            <div class="title">Cijena</div>
            <div class="content">{{ equipment.price }}€</div>
          </div>
          <div class="col-12 col-md-4 mt-2">
            <div class="title">Kategorija</div>
            <div class="content">{{ equipment.category.name }}</div>
          </div>
          <div class="col-12 col-md-4 mt-2">
            <div class="title">Potkategorija</div>
            <div class="content">{{ equipment.subcategory.name }}</div>
          </div>
          <div class="col-12 col-md-4 mt-2">
            <div class="title">status opreme</div>
            <div class="content">{{ equipment.equipment_status.name }}</div>
          </div>
          <div class="col-12 col-md-4 mt-2">
            <div class="title">Dostupno</div>
            <div class="content">{{ !equipment.available ? "Ne" : "Da" }}</div>
          </div>
          <div class="col-12 col-md-4 mt-2">
            <div class="title">Zadužio</div>
            <div class="content">
              {{
                !equipment.rented_to
                  ? "Niko"
                  : equipment.rented_to.user.full_name
              }}
            </div>
          </div>
          <div class="col-12 col-md-4 mt-2">
            <div class="title">Garancija</div>
            <div class="content" style="position: relative">
              {{ equipment.warranty_valid ? "Validna" : "Istekla" }}
              <p
                class="warranty-badge"
                :class="{
                  'warranty-valid': equipment.warranty_valid,
                  'warranty-invalid': !equipment.warranty_valid,
                }"
              >
                {{ equipment.warranty }}
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-2">
            <div class="title">Stanje</div>
            <div class="content">
              {{ !equipment.state ? "Nema stanje" : equipment.state }}
            </div>
          </div>
        </div>
        <div class="row" v-if="equipment.files.length > 0">
          <div class="col-12 mt-2">
            <div class="title">Fajlovi</div>
            <div class="row">
              <div
                class="col-12 col-md-12 col-lg-6 col-xl-6 mt-2"
                v-for="f in equipment.files"
                :key="f.id"
              >
                <file :file="f"></file>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="title">Istorija korišćenja</div>
          <div class="col-12">
            <div
              class="content row"
              v-for="h in equipment.usage_history"
              :key="h.id"
            >
              <div class="col-md-4 col-sm-6">{{ h.user }}</div>
              <div class="col-md-4 col-sm-6">
                {{ h.rented_at }} - {{ h.deleted_at }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-2"
      @hide="closeModal"
      hide-footer
      centered
      title="Dodijeli opremu"
    >
      <div class="form mb-4">
        <div class="form-group mb-3">
          <label for="user">Korisnik *</label>
          <multiselect
            id="user"
            @input="rent_form.user_id = selectedUser.id"
            v-model="selectedUser"
            :options="users"
            track-by="id"
            placeholder="Izaberi korisnika"
            selectLabel="Pritisni Enter da izabereš"
            deselectLabel="Pritisni Enter da ukloniš"
            selectedLabel="Izabrano"
            label="full_name"
            :class="{ hasErrors: rent_form.errors.has('user_id') }"
          >
            <span slot="noOptions">Nema opcija...</span>
            <span slot="noResult">Nije pronađen nijedan rezultat...</span>
          </multiselect>
          <has-error :form="rent_form" field="user_id" />
        </div>
        <!--      <span class="text-danger" v-if="form.errors.has('user_id')">{{form.errors.get('user_id')}} <br></span>-->

        <div class="form-group mb-3">
          <label for="equipment">Oprema *</label>
          <multiselect
            id="equipment"
            v-model="selectedEquipment"
            :options="equipmentRent"
            track-by="name"
            label="name"
            placeholder="Izaberi opremu"
            selectLabel="Pritisni Enter da izabereš"
            deselectLabel="Pritisni Enter da ukloniš"
            selectedLabel="Izabrano"
            disabled
            :class="{ hasErrors: rent_form.errors.has('equipment_id') }"
          >
            <span slot="noOptions">Nema opcija...</span>
            <span slot="noResult">Nije pronađen nijedan rezultat...</span>
          </multiselect>
          <has-error :form="rent_form" field="equipment_id" />
        </div>

        <div class="form-group mb-3">
          <label for="email">Bilješka</label>
          <b-form-textarea
            id="datepicker-placeholder"
            v-model="rent_form.note"
            placeholder="Unesi bilješku..."
            locale="sr"
          ></b-form-textarea>
        </div>
      </div>

      <div class="saveButton">
        <b-button
          @click.prevent="rent"
          :disabled="rent_form.busy"
          v-b-modal.modal-1
        >
          <b-spinner small v-if="rent_form.busy"></b-spinner>
          {{ $t("save") }}</b-button
        >
      </div>
    </b-modal>
  </div>
</template>
<script>
import { API_URL } from "../../_plugins/axios";
import File from "../../components/File";
import Form from "@/_plugins/form";
import HasError from "@/components/HasError";

import Multiselect from "vue-multiselect";
import i18n from "../../_plugins/i18n";

export default {
  components: { File, Multiselect, HasError },
  data() {
    return {
      equipment: null,
      users: [],
      selectedUser: null,
      selectedEquipment: null,
      equipmentRent: [],
      rentable: false,
      rent_form: new Form({
        user_id: null,
        equipment_id: null,
      }),
      loading: true,
    };
  },
  methods: {
    unrent() {
      let id = this.equipment.rented_to.id;
      this.loading = true;
      API_URL.delete(`unrent-equipment/${id}/asd`).then(() => {
        this.$toast.success(i18n.tc("notifications.success"));
        this.loading = false;
        this.getEquipment();
      });
    },
    rent() {
      this.rent_form
        .post("rent-equipment")
        .then(() => {
          this.getEquipment();
          this.$toast.success(i18n.tc("notifications.success"));
          this.$bvModal.hide("modal-2");
        })
        .catch(() => {
          // console.log("ERROR", error.data);
        });
    },
    rentModal() {
      let i = this.equipment;
      this.equipmentRent.push([{ id: i.id, name: i.name }]);
      this.rent_form.equipment_id = [i.id];
      this.selectedEquipment = { id: i.id, name: i.name };
      this.$bvModal.show("modal-2");
    },
    closeModal() {
      this.rent_form.reset();
      this.selectedEquipment = null;
      this.selectedUser = null;
      this.equipmentRent = [];
    },
    getAllUsers() {
      API_URL.get("users/all").then((response) => {
        this.users = response.data;
      });
    },
    getEquipment() {
      this.loading = true;
      this.getAllUsers();
      API_URL.get(`equipment/${this.$route.params.id}`).then((res) => {
        this.equipment = res.data.data;
        this.rentable = !!this.equipment.available;
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getEquipment();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.multiselect__option--highlight,
.multiselect__option--highlight:after {
  background: $sidebar_active_link;
}
.multiselect__tags {
  padding: 0 40px 0 8px;
  display: flex;
  align-items: center;
}
.multiselect__single {
  margin-bottom: 0;
}
</style>
